import * as React from "react"

import { ComingSoon } from "src/components/coming-soon"
import Seo from "src/components/seo"

const ContactPage = () => {
  return (
    <>
      <Seo title="Contact" />
      <ComingSoon />
    </>
  )
}

export default ContactPage
